import React from "react";
import Layout from "../../components/layout";
import ArthurEnYvonne from '../../components/portfolio/arthurEnYvonne.component';


export default function ArthurEnYvonneGalleryPage(props){


  return (
    <Layout>
    <ArthurEnYvonne/>
  </Layout>
  )
}



